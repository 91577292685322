<template>
  <b-row no-gutters>
    <b-col cols="12" class="border-bottom">
      <h5 class="font-weight-bold p-2 mb-0 text-center bg-light">
        {{ subject }}
      </h5>
    </b-col>
    <b-col cols="12" class="p-2">
      <!-- INTRO -->
      <intro
        class="mb-3"
        :name="client.firstName"
        :text="
          $t('emails.clientInitialPaymentRequest.intro', locale, {
            localizator: bookingLocalizator,
            accommodationName: accommodationName,
          })
        "
      />

      <!-- PAYMENTS LIST -->
      <div>
        <h4 class="mb-2">
          {{ $t("Pagaments", locale) }}
        </h4>

        <!-- PENDING PAYMENTS -->
        <div>
          <h5>
            <u>{{ $t("Pagaments pendents", locale) }}</u>
          </h5>

          <b-list-group flush class="my-2">
            <!-- INITIAL PAYMENT AMOUNT -->
            <b-list-group-item
              class="d-flex justify-content-between align-items-center"
            >
              <div>
                <div class="font-weight-bold">
                  {{ $t("Pagament inicial", locale) }}
                </div>
                <template
                  v-if="initialPaymentPendingAmount < initialPaymentAmount"
                >
                  <div>
                    <small>
                      {{ $t("Total", locale) }}:
                      {{ formatCurrency(initialPaymentAmount) }}
                    </small>
                  </div>
                  <div>
                    <small>
                      {{ $t("Pendent", locale) }}:
                      {{ formatCurrency(initialPaymentPendingAmount) }}
                    </small>
                  </div>
                </template>
                <template v-else>
                  <small>
                    {{ formatCurrency(initialPaymentAmount) }}
                  </small>
                </template>
              </div>
              <b-badge pill variant="warning">
                {{ $t("emails.common.waitingPayment", locale) }}
              </b-badge>
            </b-list-group-item>

            <!-- PAY WITH CREDIT CARD -->
            <b-list-group-item class="py-2">
              <div class="font-weight-bold mb-1">
                {{ $t("emails.common.creditCardPayment", locale) }}
              </div>
              <b-button block variant="primary" :href="tpvLink" target="_blank">
                {{ $t("Paga ara", locale) }}
              </b-button>
            </b-list-group-item>

            <!-- PAY WITH BANK TRANSFER -->
            <b-list-group-item class="pt-2">
              <div class="font-weight-bold mb-1">
                {{ $t("emails.common.bankTransferPayment", locale) }}
              </div>
              <b-alert class="mb-0" variant="secondary" :show="true">
                <div class="alert-body">
                  <p>
                    {{ $t("Banc", locale) }}: <b>{{ agencyBankName }}</b>
                  </p>
                  <p>
                    IBAN: <b>{{ agencyIban }}</b>
                  </p>
                  <p>
                    SWIFT/BIC: <b>{{ agencySwift }}</b>
                  </p>
                  <p>
                    {{ $t("Beneficiari", locale) }}:
                    <b>{{ agencyLegalName }}</b>
                  </p>
                  <p>
                    {{ $t("Import", locale) }}:
                    <b>{{ formatCurrency(initialPaymentPendingAmount) }}</b>
                  </p>
                  <p>
                    {{ $t("Concepte", locale) }}:
                    <b>{{ bankTransferSubject }}</b>
                  </p>
                  <p class="mt-1">
                    {{ $t("emails.common.bankTransferReceipt", locale) }}
                  </p>
                </div>
              </b-alert>
            </b-list-group-item>
          </b-list-group>
        </div>

        <template v-if="showUpcommingPayments">
          <!-- DIVIDER -->
          <b-divider dashed size="md" />

          <!-- UPCOMING PAYMENTS -->
          <div>
            <h5>
              <u>{{ $t("Propers pagaments", locale) }}</u>
            </h5>

            <b-list-group flush class="mt-2">
              <!-- FINAL PAYMENT -->
              <b-list-group-item
                v-if="finalPaymentAmount > 0"
                class="d-flex justify-content-between align-items-center"
              >
                <div>
                  <div class="font-weight-bold">
                    {{ $t("Pagament final", locale) }}
                  </div>
                  <small>
                    {{ formatCurrency(finalPaymentAmount) }} ·
                    {{ formatDateObjectToDate(finalPaymentDueDate) }}
                  </small>
                </div>
                <b-badge pill variant="info">
                  {{ $t("Programat", locale) }}
                </b-badge>
              </b-list-group-item>

              <!-- SECURITY DEPOSIT CHARGABLE BY THE AGENCY -->
              <b-list-group-item
                v-if="hasAgencyChargableSecurityDeposit"
                class="d-flex justify-content-between align-items-center"
              >
                <div>
                  <div class="font-weight-bold">
                    {{ $t("Fiança", locale) }}
                  </div>
                  <small v-if="securityDepositPendingPrice > 0">
                    {{ formatCurrency(securityDepositPendingPrice) }}
                  </small>
                </div>
                <b-badge pill variant="info">
                  {{ $t("Programat", locale) }}
                </b-badge>
              </b-list-group-item>

              <!-- ONSITE PAYMENTS -->
              <b-list-group-item
                v-if="onsitePayments.length"
                class="d-flex justify-content-between align-items-center"
              >
                <div>
                  <div class="font-weight-bold">
                    {{ $t("emails.common.onsitePayments", locale) }}
                  </div>
                  <div
                    v-for="(onsitePayment, index) in onsitePayments"
                    :key="`onsite-payment-${index}`"
                  >
                    <small>
                      {{ onsitePayment.name }}
                      <span v-if="onsitePayment.price">
                        · {{ onsitePayment.price }}
                      </span>
                    </small>
                  </div>
                </div>
                <b-badge pill variant="info">
                  {{ $t("Programat", locale) }}
                </b-badge>
              </b-list-group-item>
            </b-list-group>
          </div>
        </template>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BListGroup,
  BListGroupItem,
  BBadge,
  BButton,
  BAlert,
} from "bootstrap-vue";
import {
  formatCurrency,
  formatDateObjectToDate,
  formatDateStringToDatabaseDate,
} from "@/utils/formatters";
import {
  getPaymentFrequencyUnit,
  getServiceName,
  getTpvLink,
} from "@/utils/methods";
import BDivider from "@foravila-core/components/b-divider/BDivider.vue";
import Intro from "@/views/bookings/booking/components/mail/Intro.vue";

export default {
  components: {
    BRow,
    BCol,
    BListGroup,
    BListGroupItem,
    BBadge,
    BButton,
    BAlert,
    BDivider,
    Intro,
  },
  props: {
    locale: {
      type: String,
      default: "en-GB",
    },
  },
  data() {
    return {
      content: {},
      formatDateObjectToDate,
      formatCurrency,
    };
  },
  computed: {
    booking() {
      return this.$store.getters["booking/booking"];
    },
    bookingLocalizator() {
      return this.booking?.localizator || null;
    },
    accommodation() {
      return this.$store.getters["accommodation/accommodation"];
    },
    accommodationName() {
      return this.accommodation?.name || null;
    },
    client() {
      return this.$store.getters["booking/client"];
    },
    unchargableServices() {
      return this.$store.getters["booking/unchargableServices"];
    },
    isLastMinute() {
      return this.$store.getters["booking/isLastMinute"];
    },
    initialPaymentAmount() {
      return this.$store.getters["booking/initialPaymentAmount"];
    },
    initialPaymentPendingAmount() {
      return this.$store.getters["booking/initialPaymentPendingAmount"];
    },
    finalPaymentDueDate() {
      return this.$store.getters["booking/finalPaymentDueDate"];
    },
    finalPaymentAmount() {
      return this.$store.getters["booking/finalPaymentAmount"];
    },
    hasSecurityDeposit() {
      return this.$store.getters["booking/hasSecurityDeposit"];
    },
    hasChargableSecurityDeposit() {
      return this.$store.getters["booking/hasChargableSecurityDeposit"];
    },
    hasAgencyChargableSecurityDeposit() {
      return (
        this.hasSecurityDeposit &&
        this.hasChargableSecurityDeposit &&
        this.securityDepositPrice > 0
      );
    },
    hasOwnerChargableSecurityDeposit() {
      return (
        this.hasSecurityDeposit &&
        !this.hasChargableSecurityDeposit &&
        this.securityDepositPrice > 0
      );
    },
    securityDepositPrice() {
      return this.$store.getters["booking/securityDepositPrice"];
    },
    securityDepositPendingPrice() {
      if (!this.hasChargableSecurityDeposit) {
        return 0;
      }

      return this.$store.getters["booking/securityDepositPendingPrice"] || 0;
    },
    onsitePayments() {
      const payments = [];

      // Add the security deposit if the owner charges it on arrival
      if (this.hasOwnerChargableSecurityDeposit) {
        payments.push({
          name: this.$t("Fiança", this.locale),
          price: formatCurrency(this.securityDepositPrice),
        });
      }

      // Add the rest of the unchargable services
      if (this.unchargableServices.length) {
        this.unchargableServices.forEach((bookingService) => {
          const { service } = bookingService || {};
          payments.push({
            name: getServiceName(service, this.locale),
            price: this.bookingServiceUnitPriceText(bookingService),
          });
        });
      }

      return payments;
    },
    contentOnsitePayments() {
      const payments = [];

      // Add the security deposit if the owner charges it on arrival
      if (this.hasOwnerChargableSecurityDeposit) {
        payments.push({
          name: this.$t("Fiança", this.locale),
          price: this.securityDepositPrice,
          unit: "",
        });
      }

      // Add the rest of the unchargable services
      if (this.unchargableServices.length) {
        this.unchargableServices.forEach((bookingService) => {
          const { service, pvpPrice, paymentFrequency } = bookingService || {};
          payments.push({
            name: getServiceName(service, this.locale),
            price: pvpPrice,
            unit: getPaymentFrequencyUnit(paymentFrequency, this.locale) || "",
          });
        });
      }

      return payments;
    },
    bankTransferSubject() {
      const text = [];

      const clientName = this.client?.fullName;
      const bookingLocalizator = this.booking?.localizator;

      if (clientName) text.push(clientName);
      if (bookingLocalizator) text.push(bookingLocalizator);

      return text.join(" ");
    },
    agencyBankName() {
      return this.$store.getters["agencyInfo/bankName"];
    },
    agencyIban() {
      return this.$store.getters["agencyInfo/iban"];
    },
    agencySwift() {
      return this.$store.getters["agencyInfo/swift"];
    },
    agencyLegalName() {
      return this.$store.getters["agencyInfo/legalName"];
    },
    showUpcommingPayments() {
      return (
        this.finalPaymentAmount > 0 ||
        this.hasAgencyChargableSecurityDeposit ||
        this.onsitePayments.length
      );
    },
    subject() {
      return `${this.$t(
        "emails.clientInitialPaymentRequest.subject",
        this.locale,
        {
          localizator: this.booking?.localizator || null,
          accommodationName: this.accommodation?.name || null,
        }
      )}`;
    },
    tpvLink() {
      if (!this.initialPaymentPendingAmount) {
        return null;
      }

      const { checkin, checkout, localizator } = this.booking || {};

      return getTpvLink({
        locale: this.locale,
        accommodation: this.accommodation?.name || null,
        localizator: localizator || null,
        checkin: formatDateStringToDatabaseDate(checkin),
        checkout: formatDateStringToDatabaseDate(checkout),
        amount: (this.initialPaymentPendingAmount / 100).toFixed(2),
        name: this.client?.fullName || null,
        comment: `${this.$t("Pagament inicial", this.locale)}+${localizator}`,
      });
    },
  },
  watch: {
    content: {
      deep: true,
      handler() {
        this.$emit("content-updated", this.content);
      },
    },
  },
  created() {
    this.initContent();
  },
  methods: {
    initContent() {
      this.content = {
        locale: this.locale,
        subject: this.subject,
        clientName: this.client?.firstName || null,
        clientEmail: this.client?.email || null,
        localizator: this.booking?.localizator || null,
        accommodationName: this.accommodation?.name || null,
        initialPaymentAmount: this.initialPaymentAmount,
        initialPaymentPendingAmount: this.initialPaymentPendingAmount,
        paymentLink: this.tpvLink,
        bankName: this.agencyBankName,
        bankIban: this.agencyIban,
        bankSwift: this.agencySwift,
        bankBeneficiary: this.agencyLegalName,
        bankTransferSubject: this.bankTransferSubject,
        showUpcommingPayments: this.showUpcommingPayments,
        finalPaymentAmount: this.finalPaymentAmount,
        finalPaymentDueDate: formatDateObjectToDate(this.finalPaymentDueDate),
        hasAgencyChargableSecurityDeposit:
          this.hasAgencyChargableSecurityDeposit,
        securityDepositPendingPrice: this.securityDepositPendingPrice,
        onsitePayments: this.contentOnsitePayments,
      };
    },
    bookingServiceUnitPriceText(bookingService) {
      const { paymentFrequency, chargable, pvpPrice } = bookingService || {};

      if (
        !bookingService ||
        !paymentFrequency ||
        (paymentFrequency === "ONCE" && chargable)
      ) {
        return null;
      }

      const formattedPvpPrice = formatCurrency(pvpPrice);
      const unit = getPaymentFrequencyUnit(paymentFrequency, this.locale) || "";

      return `${formattedPvpPrice}${unit}`;
    },
  },
};
</script>
